import logo from './logo.svg';
import './App.css';
// import React, { useState, useEffect } from "react";
// function App() {
//   //const url = "https://jsonplaceholder.typicode.com/users";
//   //const url="http://localhost:40029/api/Dash";
//   //const url="http://localhost:5189/fetch-data";
//   const url="http://192.168.1.159/fetch-data";
//   const [data, setData] = useState([]);

//   const fetchInfo = () => {
//     return fetch(url)
//       .then((res) => res.json())
//       .then((d) => setData(d))
//   }


//   useEffect(() => {
//     fetchInfo();
//   }, []);

//   return (
//     <div className="App">
//       <h1 style={{ color: "green" }}>Master Db Project</h1>
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         {data.map((dataObj, index) => {
//           return (
//             <div
//               style={{
//                 width: "15em",
//                 backgroundColor: "#b0cbf5",
//                 padding: 2,
//                 borderRadius: 10,
//                 marginBlock: 10,
//                 marginLeft: "2%", 
//               }}
//             >
//               {/* <p style={{ fontSize: 20, color: 'white' }}>{dataObj.branch}</p> */}
//               <p style={{ fontSize: 18, color: 'Black', fontWeight: 'bold' , paddingBottom: '4px'}}>{dataObj.cityName}</p> 
//               <p style={{ fontSize: 18, color: 'Black', height: '20px' }}>Goods In:{dataObj.goodsInCount}</p>
//               <p style={{ fontSize: 18, color: 'Black', height: '20px' }}>Goods Out:{dataObj.goodsOutCount}</p>
//               <p style={{ fontSize: 18, color: 'Black' , height: '20px'}}>Faulty In:{dataObj.faultyInCount}</p>
//               <p style={{ fontSize: 18, color: 'Black', height: '20px' }}>Faulty Out:{dataObj.faultyOutCount}</p>  
//               <p style={{ fontSize: 18, color: 'Black', height: '20px' }}>Goods:{dataObj.GoodsCount}</p>
//               <p style={{ fontSize: 18, color: 'Black', height: '20px' }}>Faulty:{dataObj.FaultyCount}</p>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }
// export default App;
import React, { useState, useEffect } from "react";

function App() {
  const url = "https://wms.saamitradestar.com/fetch-data";
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [userChangingDates, setUserChangingDates] = useState(false);
  useEffect(() => {
   // const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
   const currentDate = new Date().toISOString().split("T")[0];  
   // Fetch the current date when the component mounts
    // const currentDate = new Date();
    setFromDate(currentDate);
    setToDate(currentDate);
    //alert("from and to date "+currentDate);
    // Call fetchInfo after setting the dates
  //fetchInfo(currentDate, currentDate);
    //fetchInfo();
  }, []); // Empty dependency array to run once on mount
// Use a separate useEffect to call fetchInfo when dates are set
useEffect(() => {
  // Only fetch if both dates are set and user isn't currently changing them
  if (!userChangingDates && fromDate && toDate) {
    fetchInfo(fromDate, toDate);
  }
}, [fromDate, toDate, userChangingDates]);

const handleFromDateChange = (e) => {
  setUserChangingDates(true);
  setFromDate(e.target.value);
  // You can set userChangingDates to false after a delay if needed
};

const handleToDateChange = (e) => {
  setUserChangingDates(true);
  setToDate(e.target.value);
  // You can set userChangingDates to false after a delay if needed
};

const handleDateChangeComplete = () => {
  setUserChangingDates(false);
  // Call fetchInfo here if you want to fetch when the user is done
  if (fromDate && toDate) {
    fetchInfo(fromDate, toDate);
  }
};

 // const fetchInfo = () => {
  const fetchInfo = (fromDate, toDate) => {
   // alert("fromdate :"+fromDate +" todate : "+toDate )
    setLoading(true); // Show loader
    // Construct the URL with query parameters for fromDate and toDate
    const fetchUrl = `${url}?fromDate=${fromDate}&toDate=${toDate}`;
   // alert("fetchURL "+fetchUrl);
    fetch(fetchUrl)
    .then((res) => res.json())
    .then((d) => {
      setData(d);
      setLoading(false); // Hide loader when data is fetched
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoading(false); // Hide loader on error
    });
  };

  const handleRefresh = () => {
    fetchInfo(fromDate, toDate);
  };
 // Define an array of colors
 const colors = ["#b0cbf5", "#f5b0cb", "#b0f5cb", "#f5f0b0","lightgrey"];

  return (
    <div className="App" style={{ paddingLeft: "1%" }}>
      <div style={{ marginBottom: "20px", display: "flex", justifyContent: "center"}}>
        <input 
          type="date" 
          value={fromDate} 
          onChange={handleFromDateChange} 
        onBlur={handleDateChangeComplete}
          style={{ marginRight: "10px" }}
        />
        <input 
          type="date" 
          value={toDate} 
          onChange={handleToDateChange} 
        onBlur={handleDateChangeComplete} // Call when input loses focus
          style={{ marginRight: "10px" }}
        />
        <button onClick={handleRefresh} style={{ padding: "10px 15px", cursor: "pointer" }}>
          Refresh
        </button>
      </div>
      <div style={{ marginBottom: "20px", display: "flex", justifyContent: "center"}}>
      <p style={{ margin: 0 ,fontSize: 16}}>Stock As On : <span  style={{  fontWeight: 'bold' }}>{toDate}
        </span>
        </p>
        <p style={{ margin: 0 ,fontSize: 16,paddingLeft: "5%"}}>Moment From : <span  style={{  fontWeight: 'bold' }}>{fromDate}   </span>  To :  <span  style={{  fontWeight: 'bold' }}> {toDate} </span>
      
        </p>
         {/* <p>Stock As On : {toDate}</p>  <p>Moment From {fromDate} To {toDate}</p> */}
      </div>
       {/* Show loader if loading is true */}
       {loading && <div style={{ textAlign: "center", fontSize: "20px", marginTop: "15%" }}>Loading...</div>}

      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center",paddingLeft:"7%",paddingRight:"7%" }}>
        {data.map((dataObj, index) => (
          <div
            key={index}
            style={{
              flex: "0 1 calc(20% - 20px)", // This allows for up to 5 tiles per row
              backgroundColor: colors[index % colors.length], // Alternate colors
              paddingBottom:"5px",
              borderRadius: 10,
              margin: "10px",
            }}
          >
           <p style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'center' ,marginTop: "3%",height:"18px"}}>{dataObj.cityName}</p>
            {/* <p style={{ fontSize: 16, textAlign: 'center' ,height:"12px",fontWeight:"500"}}>Stock</p> */}
            <p style={{ fontSize: 14, textAlign: 'center' }}>
            <span  style={{  color: 'green',fontWeight:"500"}}>Good</span>    /   <span  style={{ fontWeight:"500", color: 'red'}}>Faulty</span> :  <span  style={{  fontWeight: 'bold'  ,color: 'green'}}> {dataObj.goodsCount} </span>/  <span  style={{  color: 'red',fontWeight:"500"}}>{dataObj.faultyCount}</span>
            </p>
            <div style={{ display: "flex", justifyContent: "space-between", padding: '0 14px' }}>
              <div style={{ textAlign: 'center' }}>
                <p style={{ margin: 0 ,fontWeight:"500",color: 'green'}}>Good</p>
                <p style={{ margin: 0 ,fontSize: 14}}>In/ Out : <span  style={{  fontWeight: 'bold' }}>{dataObj.goodsInCount}   /{dataObj.goodsOutCount}</span></p>
                {/* <p style={{ margin: 0 }}>Out: {dataObj.goodsOutCount}</p> */}
              </div>
              <div style={{ textAlign: 'center',paddingLeft:"13px" }}>
                <p style={{ margin: 0 ,fontWeight:"500",color: 'red'}}>Faulty</p>
                <p style={{ margin: 0 ,fontSize: 14}}>In /Out : <span  style={{  fontWeight: 'bold' }}>{dataObj.faultyInCount}  / {dataObj.faultyOutCount}</span></p>
                {/* <p style={{ margin: 0 }}>Out: {dataObj.faultyOutCount}</p> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
